import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Team = ({ team }) => {
  console.log(team)

  return (
    <div className="pt-16 px-12 lg:px-24 mx-auto w-11/12">
      <div className="grid gap-12 mx-auto grid-cols-1 lg:grid-cols-2">
        {team.map(t => (
          <div key={t.id} className="mx-auto text-center my-6 w-full">
            <GatsbyImage
              className="object-cover w-56 h-56 rounded-full shadow mx-auto"
              image={getImage(t.image.childImageSharp.gatsbyImageData)}
              alt="Dario or Ruben"
            />
            <div className="flex flex-col justify-center mt-8 mb-2 items-center">
              <p className="text-4xl font-bold text-white">{t.name}</p>
              <p className="my-1 text-2xl text-white">{t.role}</p>
              <p className="my-4 lg:px-20 md:px-24 px-0 font-mont text-2xl tracking-wide text-white font-extralight">
                {t.bio}
              </p>
              <span className="inline-flex mt-4">
                <a
                  href
                  className="cursor-pointer text-white hover:text-kinzica-pink w-8 h-8"
                >
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
                  </svg>
                </a>
                <a
                  href
                  className="ml-4 cursor-pointer text-white hover:text-kinzica-pink w-8 h-8"
                >
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
                  </svg>
                </a>
                <a
                  href
                  className="ml-4 cursor-pointer text-white hover:text-kinzica-pink w-8 h-8"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
                  </svg>
                </a>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
