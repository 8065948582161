/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';


export const PageTitle = ({ id, title, subtitle }) => {

	return (
		<section id={id} className="bg-gray-900">

			<div className="px-4 lg:pt-64 pt-48 pb-4 mx-auto text-center lg:w-3/4 w-full">
				<h1 className="text-5xl text-kinzica-blue lg:text-8xl">{title}</h1>
				<p className="px-4 lg:px-24 mt-2 text-gray-300 text-2xl lg:text-4xl font-mont font-thin">{subtitle}</p>
			</div>
		</section>

	)
}
