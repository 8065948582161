import React, {useEffect} from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"
import { Team } from '../components/Components/Content/Team';
import { PageTitle } from '../components/Components/Headings/PageTitle';


const AboutPage = ({ data }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  const team = data.team.nodes;



  return (
    <Layout>
      <Seo
        title="Kinzica Studio | About us"
        description="Kinzica Studio is more than a place to make music. It's a place for musicians to work, collaborate, and explore their creativity and passion."
        url="www.kinzicastudio.com/about"
        keywords={[
          "Kinzica Studio About us", "Kinzica Studio Ruben Sonnoli", "Kinzica studio Dario Ferrante",
          "Kinzica Studio Music studi",
        ]}
      />
      <PageTitle title="Who we are" subtitle="" />
      <p className="lg:leading-relaxed leading-10 text-center lg:w-1/2 md:w-4/5 w-full mx-auto px-4 text-gray-300 text-xl lg:text-3xl font-mont font-extralight">
        Kinzica  studio is a collective of musicians and sound professionals the focus on the composition and production of music for media.
      </p>
      <p className="lg:leading-relaxed leading-10 text-center lg:w-1/2 md:w-4/5 w-full mx-auto px-4 text-gray-300 text-xl lg:text-3xl font-mont font-extralight">
        Kinzica provides different services like studio recording, composition, production and sound design.
      </p>
      <p className="lg:leading-relaxed leading-10 text-center lg:w-1/2 md:w-4/5 w-full mx-auto px-4 text-gray-300 text-xl lg:text-3xl font-mont font-extralight">

        Our goal is to offer clients a diversified approach for every project we work on, taking care of the music from the initial idea to the recordings and the final production.
      </p>
      <Team team={team} />
    </Layout>
  )
}
export default AboutPage



export const query = graphql`
query About {
  team: allTeamJson {
    nodes {
      bio
      id
      role
      instagram
      linkedin
      name
      image {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
}
`;